<template>
  <div class="login">
    <!-- 特效背景组件 -->
    <!--<particles></particles>-->
    <indexbg />
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>云影像大数据平台</span>
      </div>
      <el-form :model="loginInfo" :rules="rules" ref="loginForm">
        <el-form-item prop="username">
          <el-input v-model="loginInfo.username" placeholder="请输入登录账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginInfo.password " placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btns" type="primary" @click="submitForm()" :disabled="isDisable">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>


<script>
import Cookies from 'js-cookie';
import indexbg from "./components/indexbg";
import RSA from "../../RSA";
//import Particles from "./components/Particles";

export default {
  name: "index",
  components: { indexbg },
  data() {
    return {
      isDisable: false,
      loginInfo: {
        username: "",
        password: "",
        loginDevice: "1"
      },
      encryptionLoginInfo: {
        username: "",
        password: "",
        loginDevice: "1"
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入登录账号",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "change"
          }
        ]
      }
    };
  },
  mounted() {
    let code = this.$route.query["code"];
    //console.log( this.$route.query,' this.$route.query')
    if (code == 103001001) {
      this.$message.error("登录失效，请重新登录！");
      sessionStorage.clear();
    }
  },
  methods: {
    /* 登录 */
    submitForm() {
      //公钥
      let PUBLIC_KEY =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCsf7DoqhhqRfD8QuRW032ZNz5jRkvfsbqXfh7d10qOFLVGyGBlPC3LiDl0tiSLDKlxKzuZoSi8z8agZu1DrL89PL6qwSNXqeVQDRBsrGqNJWUaV263sEljZsmnZHp3ITVlLt+epbSCwJGFQGuVwbj8kM4tQM4rC3VQvHME7ijQyQIDAQAB";
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(PUBLIC_KEY);
      this.encryptionLoginInfo.username = encrypt.encrypt(
        this.loginInfo.username
      );
      this.encryptionLoginInfo.password = encrypt.encrypt(
        this.loginInfo.password
      );

      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 2000);
      this.$refs["loginForm"].validate(valid => {
        if (valid) {
          this.axios
            .postData(
              "/api/user/signIn.service",
              "loginInfo",
              this.encryptionLoginInfo
            )
            .then(res => {
              if (res["userId"]) {
                sessionStorage.setItem("userInfo", JSON.stringify(res));
                Cookies.set('Authorization', res.token);
                // 存默认的账号经纬度信息假数据
                //sessionStorage.setItem("loginInfo", JSON.stringify(this.loginInfo));
                this.$router.push(`/home`);
                this.$message.success("登录成功");
              } else {
                this.$message.error(res["errorMsg"]);
              }
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  position: relative;

  .el-card {
    border: none;
    color: #fff;
    box-shadow: none;
  }
  .box-card {
    padding: 18px 20px;
    border: none;
    width: 25%;
    position: absolute;
    z-index: 999;
    top: 200px;
    left: 50%;
    margin-left: -200px;
    background: none;

    .clearfix {
      //font-size: 28px;
      text-align: center;
      border: none;
      span {
        font-size: 36px !important;
      }
    }

    .btns {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
}
</style>
<style  lang="scss">
.el-card__header {
  border: none;
}
</style>
